#from-destination, #to-destination{
	width: 42%;
}
#datepicker, #datepicker-b {
	margin: 5px 0 0 0;
}
.other-services .bus-fleet {
	float: right;
}
html[lang="ca"] .bus-fleet {
background: url(../img/bus-fleet-ca-ie.png) no-repeat center center;
}
.main-content {
background: url(../img/bg.png) #f4f4f4;
padding: 20px;
min-height: 600px;
height: auto;
}
.rwd-table td {
	background: white;
}
.semantic-content .modal-close:after{margin-left: 310px;}


.style-select {
	width: 90%;
	border-radius: 4px;
	
	select {
		background: white;
		width: 100%!important;
		padding: 0 4px;
		border: none;
	}
}